import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import {
  convertDateToUnixTimestamp,
  convertUnixTimestampToDate,
} from 'business-logic';
import { AccountCommissionGrouping } from 'common-types';
import { first } from 'rxjs';
import { CommissionService } from '../../../services/commission/commission.service';

type SearchForm = {
  dateStart: FormControl<Date | null>;
  dateEnd: FormControl<Date | null>;
};

@Component({
  selector: 'jfw-paid-group-listing',
  templateUrl: './paid-group-listing.component.html',
  styleUrls: ['./paid-group-listing.component.scss'],
})
export class PaidGroupListingComponent implements OnInit {
  accountCommissionGroups: Array<AccountCommissionGrouping> | undefined;
  accountCommissionGroupsView: Array<boolean> = [];
  showSpinner = false;
  displayedColumns: string[] = [
    'commissionAccountNumber',
    'approvedOn',
    'approvedBy',
    'timePeriod',
    'totalCommission',
    'process',
  ];
  public searchForm = new FormGroup<SearchForm>({
    dateStart: new FormControl<Date | null>(null),
    dateEnd: new FormControl<Date | null>(null),
  });
  constructor(
    private commissionService: CommissionService,
    private location: Location,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.searchLocalStorageRange();
  }

  goBack() {
    this.location.back();
  }

  searchLocalStorageRange(): void {
    let dateStart: number | undefined;
    let dateEnd: number | undefined;
    const localStorageSearchParams =
      this.commissionService.getPaidCommissionsSearchParams();
    console.warn(localStorageSearchParams);
    if (localStorageSearchParams !== null) {
      const dateParams = JSON.parse(localStorageSearchParams);
      dateStart = JSON.parse(dateParams.startDate);
      dateEnd = JSON.parse(dateParams.endDate);
      if (
        dateStart !== undefined &&
        dateStart !== null &&
        dateEnd !== undefined &&
        dateEnd !== null
      ) {
        this.searchForm.controls.dateStart.setValue(
          convertUnixTimestampToDate(dateStart),
        );

        this.searchForm.controls.dateEnd.setValue(
          convertUnixTimestampToDate(dateEnd),
        );
      }
    }

    const dateStartValue = this.searchForm.controls.dateStart.value;
    if (dateStartValue) {
      dateStart = convertDateToUnixTimestamp(dateStartValue.toString());
    }

    const dateEndValue = this.searchForm.controls.dateEnd.value;
    if (dateEndValue) {
      dateEnd = convertDateToUnixTimestamp(dateEndValue.toString());
    }
    if (dateStart !== undefined && dateEnd !== undefined) {
      this.showSpinner = true;
      this.commissionService
        .getPaidCommissionGroupByRange(dateStart, dateEnd)
        .pipe(first())
        .subscribe({
          next: (accountCommissionGroupings) => {
            this.accountCommissionGroups = accountCommissionGroupings;
            this.setPaidAccountView();
            this.showSpinner = false;
          },
        });
    }
  }

  public onSubmit(): void {
    let dateStart: number | undefined;
    let dateEnd: number | undefined;

    const dateStartValue = this.searchForm.controls.dateStart.value;
    if (dateStartValue) {
      dateStart = convertDateToUnixTimestamp(dateStartValue.toString());
    }

    const dateEndValue = this.searchForm.controls.dateEnd.value;
    if (dateEndValue) {
      dateEnd = convertDateToUnixTimestamp(dateEndValue.toString());
    }
    if (dateStart !== undefined && dateEnd !== undefined) {
      this.commissionService.setPaidCommissionsSearchParams(dateStart, dateEnd);
      this.showSpinner = true;
      this.commissionService
        .getPaidCommissionGroupByRange(dateStart, dateEnd)
        .pipe(first())
        .subscribe({
          next: (accountCommissionGroupings) => {
            this.accountCommissionGroups = accountCommissionGroupings;
            this.setPaidAccountView();
            this.showSpinner = false;
          },
        });
    }
  }

  getPaidGroupCommissionsByRange(): void {}

  setPaidAccountView(): void {
    this.accountCommissionGroups?.forEach((accountCommissionGroup, index) => {
      if (accountCommissionGroup.paid) {
        this.accountCommissionGroupsView[index] = true;
      } else {
        this.accountCommissionGroupsView[index] = false;
      }
    });
  }

  toggleProcessGroup(index: number) {
    this.accountCommissionGroupsView[index] =
      !this.accountCommissionGroupsView[index];
  }

  async processAccountGroupings() {
    this.showSpinner = true;
    const selectedAccounts = this.accountCommissionGroups?.map(
      (accountCommissionGroup, index) => {
        if (this.accountCommissionGroupsView[index]) {
          accountCommissionGroup.paid = true;
          accountCommissionGroup.commissionStatus = 'paid';
          return accountCommissionGroup;
        }
        return accountCommissionGroup;
      },
    );
    const accountCommissionGroupsToProcess = selectedAccounts?.filter(
      (accountCommissionGroup) => accountCommissionGroup.paid === true,
    );
    if (accountCommissionGroupsToProcess) {
      accountCommissionGroupsToProcess.map((accountCommissionGroup) => {
        this.setPaidForCommissionsByGroup(accountCommissionGroup);
        return accountCommissionGroup;
      });
      if (accountCommissionGroupsToProcess.length) {
        this.commissionService
          .payAccountCommissionGroup(accountCommissionGroupsToProcess)
          .subscribe((paidCommissionGroups) => {
            this.router.navigate(['/confirmation']);
          });
      }
    }
  }

  setPaidForCommissionsByGroup(
    accountCommissionGroup: AccountCommissionGrouping,
  ) {
    accountCommissionGroup.commissions = accountCommissionGroup.commissions.map(
      (commission) => {
        commission.commissionStatus = 'paid';
        return commission;
      },
    );
  }
}
