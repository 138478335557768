import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { getCurrentUnixTimestamp } from 'business-logic';
import { AccountCommissionGrouping } from 'common-types';
import { first } from 'rxjs';
import { CommissionService } from '../../../services/commission/commission.service';

type SearchForm = {
  dateStart: FormControl<Date | null>;
  dateEnd: FormControl<Date | null>;
};

@Component({
  selector: 'jfw-commission-approved',
  templateUrl: './commission-approved.component.html',
  styleUrls: ['./commission-approved.component.scss'],
})
export class CommissionApprovedComponent implements OnInit {
  accountCommissionGroups: Array<AccountCommissionGrouping> | undefined;
  accountCommissionGroupsView: Array<boolean> = [];
  showSpinner = false;
  displayedColumns: string[] = [
    'commissionAccountNumber',
    'approvedOn',
    'approvedBy',
    'timePeriod',
    'totalCommission',
    'process',
  ];
  public searchForm = new FormGroup<SearchForm>({
    dateStart: new FormControl<Date | null>(null),
    dateEnd: new FormControl<Date | null>(null),
  });
  constructor(
    private commissionService: CommissionService,
    private location: Location,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.showSpinner = true;
    this.commissionService
      .getApprovedCommissionGroup()
      .pipe(first())
      .subscribe({
        next: (accountCommissionGroupings) => {
          this.accountCommissionGroups = accountCommissionGroupings;
          this.setPaidAccountView();
          this.showSpinner = false;
        },
      });
  }

  goBack() {
    this.location.back();
  }

  setPaidAccountView(): void {
    this.accountCommissionGroups?.forEach((accountCommissionGroup, index) => {
      if (accountCommissionGroup.paid) {
        this.accountCommissionGroupsView[index] = true;
      } else {
        this.accountCommissionGroupsView[index] = false;
      }
    });
  }

  toggleProcessGroup(index: number) {
    this.accountCommissionGroupsView[index] =
      !this.accountCommissionGroupsView[index];
  }

  async processAccountGroupings() {
    this.showSpinner = true;
    const paidOn = getCurrentUnixTimestamp();
    const selectedAccounts = this.accountCommissionGroups?.map(
      (accountCommissionGroup, index) => {
        if (this.accountCommissionGroupsView[index]) {
          accountCommissionGroup.paid = true;
          accountCommissionGroup.paidCommission = this.getTotalCommissionsToPay(
            accountCommissionGroup,
          );
          accountCommissionGroup.commissionStatus = 'paid';
          accountCommissionGroup.paidOn = paidOn;
          return accountCommissionGroup;
        }
        return accountCommissionGroup;
      },
    );
    const accountCommissionGroupsToProcess = selectedAccounts?.filter(
      (accountCommissionGroup) => accountCommissionGroup.paid === true,
    );
    if (accountCommissionGroupsToProcess) {
      accountCommissionGroupsToProcess.map((accountCommissionGroup) => {
        this.setPaidForCommissionsByGroup(accountCommissionGroup, paidOn);
        return accountCommissionGroup;
      });
      if (accountCommissionGroupsToProcess.length) {
        this.commissionService
          .payAccountCommissionGroup(accountCommissionGroupsToProcess)
          .subscribe((paidCommissionGroups) => {
            this.router.navigate(['/commission/confirmation']);
          });
      }
    }
  }

  getTotalCommissionsToPay(
    accountCommissionGroup: AccountCommissionGrouping,
  ): number {
    if (
      accountCommissionGroup.pastDueBalance &&
      accountCommissionGroup.pastDueBalance !== 0
    ) {
      return (
        accountCommissionGroup.totalCommission -
        accountCommissionGroup.pastDueBalance
      );
    } else {
      return accountCommissionGroup.totalCommission;
    }
  }

  setPaidForCommissionsByGroup(
    accountCommissionGroup: AccountCommissionGrouping,
    paidDate: number,
  ) {
    accountCommissionGroup.commissions = accountCommissionGroup.commissions.map(
      (commission) => {
        commission.commissionStatus = 'paid';
        commission.paidDate = paidDate;
        return commission;
      },
    );
  }
}
