import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { requireAppCheck } from '@jfw-library/shared/app-check';
import {
  DealerPortalEnvironment,
  EcommerceMainEnvironment,
} from 'common-types';
import { Observable, firstValueFrom, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UserEcomSettingsService {
  private USERS_REST_API_SERVER = this.environment.user_rest_api_server_url;

  constructor(
    private httpClient: HttpClient,
    @Inject('environment')
    private environment: EcommerceMainEnvironment | DealerPortalEnvironment,
  ) {}

  public async apiGetUserGeoLocation(): Promise<any> {
    const apiUrl = `${this.USERS_REST_API_SERVER}/geo-locate`;
    try {
      return await firstValueFrom(
        this.httpClient
          .get(apiUrl, { ...requireAppCheck })
          .pipe(catchError(this.handleError)),
      );
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  private handleError(err: HttpErrorResponse): Observable<never> {
    // in a real world app, we may send the server to some remote logging infrastructure
    // instead of just logging it to the console
    let errorMessage: string;
    if (err.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errorMessage = `Backend returned code ${err.status}: ${err.message}`;
    }
    console.error(err);
    return throwError(() => errorMessage);
  }
}
