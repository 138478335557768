import { Location } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { Auth } from '@angular/fire/auth';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import {
  convertDateToUnixTimestamp,
  convertUnixTimestampToDate,
  getCurrentUnixTimestamp,
} from 'business-logic';
import { AccountCommissionGrouping } from 'common-types';
import { first } from 'rxjs/operators';
import { CommissionService } from '../../../services/commission/commission.service';

type SearchForm = {
  dateStart: FormControl<Date | null>;
  dateEnd: FormControl<Date | null>;
};

@Component({
  selector: 'jfw-commission-approve',
  templateUrl: './commission-approve.component.html',
  styleUrls: ['./commission-approve.component.scss'],
})
export class CommissionApproveComponent implements OnInit {
  accountCommissionGroups: Array<AccountCommissionGrouping> | undefined;
  userId: string | undefined;
  showSpinner = false;
  displayedColumns: string[] = [
    'commissionAccountNumber',
    'rentalCommission',
    'purchaseCommission',
    'totalCommission',
    'details',
    'pastDueBalance',
    'approved',
  ];
  isSaving = false;
  public searchForm = new FormGroup<SearchForm>({
    dateStart: new FormControl<Date | null>(null),
    dateEnd: new FormControl<Date | null>(null),
  });

  private auth = inject(Auth);

  constructor(
    private commissionService: CommissionService,
    private location: Location,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.searchLocalStorageRange();
  }

  searchLocalStorageRange(): void {
    let dateStart: number | undefined;
    let dateEnd: number | undefined;
    const localStorageSearchParams =
      this.commissionService.getUnpaidCommissionsSearchParams();
    if (localStorageSearchParams !== null) {
      const dateParams = JSON.parse(localStorageSearchParams);
      dateStart = JSON.parse(dateParams.startDate);
      dateEnd = JSON.parse(dateParams.endDate);
      if (
        dateStart !== undefined &&
        dateStart !== null &&
        dateEnd !== undefined &&
        dateEnd !== null
      ) {
        this.searchForm.controls.dateStart.setValue(
          convertUnixTimestampToDate(dateStart),
        );

        this.searchForm.controls.dateEnd.setValue(
          convertUnixTimestampToDate(dateEnd),
        );
      }
    }
    const dateStartValue = this.searchForm.controls.dateStart.value;
    if (dateStartValue) {
      dateStart = convertDateToUnixTimestamp(dateStartValue.toString());
    }

    const dateEndValue = this.searchForm.controls.dateEnd.value;
    if (dateEndValue) {
      dateEnd = convertDateToUnixTimestamp(dateEndValue.toString());
    }
    if (dateStart !== undefined && dateEnd !== undefined) {
      this.showSpinner = true;
      this.commissionService
        .getUnapprovedCommissions(dateStart, dateEnd)
        .pipe(first())
        .subscribe({
          next: (accountCommissionGroupings) => {
            accountCommissionGroupings = accountCommissionGroupings.map(
              (group) => ({
                ...group,
                approvedFrom: dateStart,
                approvedTo: dateEnd,
              }),
            );
            this.accountCommissionGroups = accountCommissionGroupings;
            this.showSpinner = false;
          },
        });
    }
  }

  public onSubmit(): void {
    let dateStart: number | undefined;
    let dateEnd: number | undefined;

    const dateStartValue = this.searchForm.controls.dateStart.value;
    if (dateStartValue) {
      dateStart = convertDateToUnixTimestamp(dateStartValue.toString());
    }

    const dateEndValue = this.searchForm.controls.dateEnd.value;
    if (dateEndValue) {
      dateEnd = convertDateToUnixTimestamp(dateEndValue.toString());
    }

    if (dateStart !== undefined && dateEnd !== undefined) {
      this.commissionService.setUnpaidCommissionsSearchParams(
        dateStart,
        dateEnd,
      );
      this.showSpinner = true;
      this.commissionService
        .getUnapprovedCommissions(dateStart, dateEnd)
        .pipe(first())
        .subscribe({
          next: (accountCommissionGroupings) => {
            accountCommissionGroupings = accountCommissionGroupings.map(
              (group) => ({
                ...group,
                approvedFrom: dateStart,
                approvedTo: dateEnd,
              }),
            );
            this.accountCommissionGroups = accountCommissionGroupings;
            this.showSpinner = false;
          },
        });
    }
  }

  goBack() {
    this.location.back();
  }

  toggleApproveAccount(accountCommissionGrouping: AccountCommissionGrouping) {
    accountCommissionGrouping.approved = !accountCommissionGrouping.approved;
  }

  approveCommissions() {
    this.isSaving = true;
    if (this.accountCommissionGroups !== undefined) {
      let approvedCommissionGroups: Array<AccountCommissionGrouping> = [];
      this.accountCommissionGroups.forEach(
        (accountCommissionGroup: AccountCommissionGrouping) => {
          if (accountCommissionGroup.approved === true) {
            const currentUser = this.auth.currentUser;

            accountCommissionGroup.approvedOn = getCurrentUnixTimestamp();
            accountCommissionGroup.approvedById = currentUser?.uid;
            accountCommissionGroup.approvedBy = currentUser?.displayName ?? '';
            accountCommissionGroup.commissionStatus = 'approved';
            accountCommissionGroup.approvedCommissionAmount =
              accountCommissionGroup.totalCommission -
                (accountCommissionGroup.pastDueBalance ?? 0) <
              0
                ? 0
                : accountCommissionGroup.totalCommission -
                  (accountCommissionGroup.pastDueBalance ?? 0);
            approvedCommissionGroups.push(accountCommissionGroup);
          }
        },
      );
      try {
        this.commissionService
          .approveAccountCommissionGroups(approvedCommissionGroups)
          .subscribe((ob) => {
            console.log(ob);
            this.router.navigate(['/commission/approve/success']);
          });
      } catch (error) {
        alert('Something went wrong approving the grouping');
        this.isSaving = false;
      }
    }
  }
}
